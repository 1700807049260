import React from "react"
import { Link } from "gatsby"
import webportalimage from "../../assets/images/banners/icon-pack/glenn-carstens-peters-npxXWgQ33ZQ-unsplash.jpg"

import b2b from '../../assets/images/banners/icon-pack/b2b.png'
import ecommerce from '../../assets/images/banners/icon-pack/ecommerce.png'
import intranet from '../../assets/images/banners/icon-pack/lan.png'
import enterprise from '../../assets/images/banners/icon-pack/skyline.png'
import customers from '../../assets/images/banners/icon-pack/support.png'
import web from '../../assets/images/banners/icon-pack/world-wide-web.png'

import "../../assets/css/style.css"
import "../../assets/css/custom.css"
// import "../../assets/js/vendor/bootstrapp.min.js"
import "../../assets/css/vendor/vendor.min.css"
import "../../assets/css/plugins/plugins.min.css"

const WebPortal = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div
          className="about-banner-wrap banner-space bg-img webportal"
          data-bg="assets/images/banners/new-banners/web-portal.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="about-banner-content text-center">
                  <h1 className="mb-15 text-white">Web Portal Solution</h1>
                  <h5 className="font-weight--normal text-white">
                    Aamanto builds adaptive solutions to power end customers
                    regardless of the technologies used.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--=========== About Company Area Start ==========-->
             <!--=========== Service Projects Wrapper Start =============--> */}
        <div className="service-projects-wrapper section-space--ptb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h6 className="section-sub-title">Our IT Solution</h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-xl-6 order-2 order-lg-1 v1-d-f">
                <div className="v1-dt-box-0-text">
                  <h2 className="heading">Web Portal <span className="text-color-primary">Solutions</span></h2>
                  We provide a wide range of custom enterprise
                  technology solutions to match your company's needs and
                  deliver rapid and efficient results.
                  <div className="mt-30 title-wrap">
                    <div className="mb-2">
                      <h5 className="text">Development</h5>
                      We provide custom portals that are compatible with a
                      wide range of corporate environments and
                      infrastructure.
                    </div>

                    <div className="mb-2">
                      <h5 className="text">Integration</h5>
                      We combine disparate services and apps into a
                      single, secure web environment.
                    </div>

                    <div className="mb-2">
                      <h5 className="text">Optimization</h5>
                      We update and improve portal solutions and their
                      components that are obsolete and ineffective.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 order-1 order-lg-2">
                <div className="v1-dt-box-0-img">
                  <img className="img-fluid" src={webportalimage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--=========== Service Projects Wrapper End =============--> */}

        {/* <!--===========  feature-large-images-wrapper  Start =============--> */}
        <div className="feature-large-images-wrapper bg-gray section-space--pb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* <!-- section-title-wrap Start --> */}
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h2 className="heading">Our Major Vectors of <span className="text-color-primary">Web Portal Development</span></h2>
                </div>
                {/* <!-- section-title-wrap Start --> */}
              </div>
            </div>


            {/* <div className="row">
              <div className="col-12">
                <div className="feature-images__six">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div
                          className="image-box-wrap"
                          style={{ height: "545px" }}
                        >
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={quality} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Ecommerce Portals</h5>
                            <div className="text">
                              We help businesses establish multi-vendor markets,
                              trade portals, auctions, bidding platforms,
                              eCommerce aggregators, and other specialized
                              solutions using eCommerce portal development.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div
                          className="image-box-wrap"
                          style={{ height: "545px" }}
                        >
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={speed} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Customer Portals</h5>
                            <div className="text">
                              Our technical solutions help businesses engage
                              with their customers by providing a wide range of
                              features such as streamlined product and service
                              discovery, continuing multichannel communication,
                              and secure transactions.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img
                                className="img-fulid"
                                src={security}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Enterprise Portals</h5>
                            <div className="text">
                              We offer multi-purpose enterprise portals,
                              corporate intranets, extranets, and user-centric
                              workspaces with sophisticated collaboration tools,
                              automated processes, and built-in analytics to
                              serve a range of business requirements.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div
                          className="image-box-wrap"
                          style={{ height: "520px" }}
                        >
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={quality} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Web Portal</h5>
                            <div className="text">
                              Our portal developers combine engineering
                              expertise with a business sense to produce
                              dependable solutions that help businesses manage
                              internal and external interactions.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div
                          className="image-box-wrap"
                          style={{ height: "520px" }}
                        >
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={speed} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">B2B Portals</h5>
                            <div className="text">
                              Through the appropriate combination of features,
                              Aamanto creates B2B portals that serve as secure
                              hubs for businesses and their partners to organize
                              barrier-free collaboration.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img
                                className="img-fulid"
                                src={security}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Intranet Portals</h5>
                            <div className="text">
                              Aamanto develops intranet portals to satisfy your
                              specific collaboration needs, whether its
                              document-centric collaboration, project
                              management, or internal and external team
                              connections.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div class="v1-box-2-parent">
              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={ecommerce} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Ecommerce Portals</h5>
                            <div className="v1-text-2">
                              We help businesses establish multi-vendor markets, trade portals, auctions, bidding platforms, eCommerce aggregators, and other specialized solutions using eCommerce portal development.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={customers} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Customer Portals</h5>
                            <div className="v1-text-2">
                              Our technical solutions help businesses engage with their customers by providing a wide range of features such as streamlined product and service discovery, continuing multichannel communication, and secure transactions.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={enterprise} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Enterprise Portals</h5>
                            <div className="v1-text-2">
                              We offer multi-purpose enterprise portals, corporate intranets, extranets, and user-centric workspaces with sophisticated collaboration tools, automated processes, and built-in analytics to serve a range of business requirements.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={web} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Web Portal</h5>
                            <div className="v1-text-2">
                              Our portal developers combine engineering expertise with a business sense to produce dependable solutions that help businesses manage internal and external interactions.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={b2b} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">B2B Portals</h5>
                            <div className="v1-text-2">
                              Through the appropriate combination of features, Aamanto creates B2B portals that serve as secure hubs for businesses and their partners to organize barrier-free collaboration.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={intranet} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Intranet Portals</h5>
                            <div className="v1-text-2">
                              Aamanto develops intranet portals to satisfy your specific collaboration needs, whether its document-centric collaboration, project management, or internal and external team connections.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

            </div>


          </div>
        </div>
        {/* <!--===========  feature-large-images-wrapper  End =============-->
            <!--=========== About Company Area End ==========-->
            
            <!--========= More Services Start ===========--> */}
        <div className="about-delivering-wrapper section-space--pb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h2 className="heading">Business Centric <span className="text-color-primary">Web Portal Design</span></h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Media Portals</h5>
                      <div className="desc">
                        For advanced content management and dissemination,
                        Aamanto offers media portal creation. We give
                        appropriate technology solutions to our customers to
                        enable new methods of digital traffic monetization.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Patient Portals </h5>
                      <div className="desc">
                        Our extensive experience in the development of
                        healthcare portals enables us to provide patients with
                        useful toolsets to help them stay proactive in their
                        medical treatment.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Vendor Portals </h5>
                      <div className="desc">
                        We provide custom portals for businesses and their
                        suppliers to allow for collaborative supply chains,
                        simpler accounting and financial control, and total
                        visibility into supplier evaluation.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Travel Portals</h5>
                      <div className="desc">
                        Companies that choose Aamanto for travel portal
                        development gain a significant tool for providing good
                        travel experiences to their clients and standing out in
                        a crowded travel market.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--========= More Services End ===========--> */}
      </div>
    </div>
  )
}

export default WebPortal
