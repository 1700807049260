import * as React from "react"
import Layout from "../components/layout"
import WebPortal from "../components/Solutions/WebPortal"






const web = () => {
  return (
  <Layout>
   <WebPortal/>
  </Layout>
)
};
export default web;
